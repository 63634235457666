@font-face {
font-stretch: condensed;
font-family: '__ringsideCondensed_544245';
src: url(/_next/static/media/f646bc783823a8ac-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}@font-face {font-family: '__ringsideCondensed_Fallback_544245';src: local("Arial");ascent-override: 110.55%;descent-override: 23.03%;line-gap-override: 4.61%;size-adjust: 86.84%
}.__className_544245 {font-family: '__ringsideCondensed_544245', '__ringsideCondensed_Fallback_544245';font-weight: 600
}

